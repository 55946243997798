section {
  justify-content: start;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25vw, 1fr));
  grid-gap: 1rem;
}

@media screen and (max-width: 1024px) {
  section {
    grid-template-columns: repeat(auto-fill, minmax(33vw, 1fr));
  }
}

@media screen and (max-width: 768px) {
  section {
    grid-template-columns: repeat(auto-fill, minmax(50vw, 1fr));
  }
}

.event-card {
  border-radius: 0.375rem;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.event-card:hover {
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.card-content {
  display: flex;
  flex-direction: column;
  padding-inline: 1rem;
  padding-block: 0.5rem;
}
